<template>
  <v-container>
    <v-row>
      <v-col :cols="12">
        <span class="text-h5">
          Usuários
          <v-btn
            fab
            color="primary accent-2"
            x-small
            @click="addItem"
            v-auth="'USER.ADD'"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12">
        <v-card>
          <v-card-text>
            <Paginator
              v-bind.sync="userList.pagingOptions"
              @page="pageSelected"
            ></Paginator>

            <v-data-table
              :headers="userList.headers"
              :items="userList.data"
              :items-per-page="25"
              :disable-sort="true"
              :loading="userList.loading"
              no-data-text=""
              loading-text="Carregando dados..."
              class="app-table hover-table"
              @click:row="viewItem"
              hide-default-footer
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  small
                  :color="item.status | enum(data.userStatus, 'id', 'class')"
                  dark
                  >{{ item.status | enum(data.userStatus) }}</v-chip
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { eventHub } from "@/main";
import userStatus from "@/common/data/user-status.data";
import userApi from "@/common/api/user.api";
import clientApi from "@/common/api/client.api";
import Paginator from "@/components/layout/Paginator";

export default {
  name: "UserList",

  components: { Paginator },

  data: () => ({
    data: {
      userStatus: userStatus,
    },

    userList: {
      filter: {},
      headers: [
        { text: "Nome", value: "name" },
        { text: "E-mail", value: "email" },
        { text: "Status", value: "status", align: "center" },
      ],
      data: [],
      loading: false,
      pagingOptions: {
        currentPage: 1
      },
    },
  }),

  created() {
    this.list();
  },

  mounted() {
    eventHub.$on("SELECTED_CLIENT", () => this.list(true));
  },

  methods: {
    pageSelected(page) {
      this.userList.pagingOptions.currentPage = page;
      this.list();
    },

    addItem() {
      this.$router.push(`usuarios/novo`);
    },

    list(resetPage) {
      if (resetPage) {
        this.userList.pagingOptions.currentPage = 1;
      }

      this.userList.filter.client_id = clientApi.getSelectedClient().id;
      this.userList.filter.page = this.userList.pagingOptions.currentPage;
      this.userList.filter.size = this.userList.pagingOptions.size;

      this.userList.loading = true;
      userApi.list(this.userList.filter).then((result) => {
        this.userList.loading = false;
        this.userList.data = result.data.data;
        this.userList.pagingOptions = result.data.pagingOptions;
      });
    },
    viewItem(item) {
      this.$router.push(`usuarios/${item.id}`);
    },
  },
};
</script>